import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import { AuthProvider } from './Auth/AuthProvider';
import { Amplify, Storage } from 'aws-amplify';

const root = ReactDOM.createRoot(document.getElementById('root'));


var config = {
  Auth: {
      region: 'ap-southeast-1',
      userPoolId: 'ap-southeast-1_50rvJ1zHA',
      userPoolWebClientId: '6jq3mt6s4c58pc5ro8j21mjiv8',
      identityPoolId: 'ap-southeast-1:98bca2fe-4e31-4e4c-9efe-85fbbd6dbfb3'
  },
  "aws_appsync_graphqlEndpoint": 'https://f7kej2lswba7npnjb5mhtxmv4a.appsync-api.ap-southeast-1.amazonaws.com/graphql',
  "aws_appsync_region": 'ap-southeast-1',
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": "da2-w227ggj7v5cuzm6yxzs3ufibcu",
  Storage: {
      AWSS3: {
          bucket: 'happy-journey-with-joyce',
          region: 'ap-southeast-1'
      }
  },
}


Amplify.configure(config);
root.render(
  <BrowserRouter>
    {/* <AuthProvider> */}
      <App />
    {/* </AuthProvider> */}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

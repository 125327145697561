import './App.css';
import { Route, Routes, useLocation, Redirect, Navigate } from 'react-router-dom';
import React from 'react';
import Layout from './components/Layout';
import AuthProvider from './auth/AuthProvider';
import AuthGuard from './auth/AuthGuard';
import LoginGuard from './auth/LoginGuard';

const Login = React.lazy(() => import("./pages/Login"));
const Blogs = React.lazy(() => import("./pages/Blogs"));
const BlogDetails = React.lazy(() => import("./pages/Blogs/BlogDetails"));
const Schedule = React.lazy(() => import("./pages/Schedule"));
const ScheduleDetails = React.lazy(() => import("./pages/Schedule/ScheduleDetails"));

function App() {
	const location = useLocation()
  
	return (
        <AuthProvider>
			<Routes location={location} key={location.pathname}>
				<Route element={<AuthGuard />}>
					<Route exact path="/" element={<Layout />}>
						<Route
							index
							element={
								<React.Suspense fallback={<div>...</div>}>
									{/* <Dashboard /> */}
									
        							<Navigate to="/blogs" />
								</React.Suspense>
							}
						/>
						<Route
							path="/blogs"
							element={
								<React.Suspense fallback={<div>...</div>}>
									<Blogs />
								</React.Suspense>
							}
						/>
						<Route
							path="/blogs/:link"
							element={
							<React.Suspense fallback={<div>...</div>}>
								<BlogDetails />
							</React.Suspense>
							}
						/>
						<Route
							path="/schedule"
							element={
								<React.Suspense fallback={<div>...</div>}>
									<Schedule />
								</React.Suspense>
							}
						/>
						<Route
							path="/schedule/:link"
							element={
							<React.Suspense fallback={<div>...</div>}>
								<ScheduleDetails />
							</React.Suspense>
							}
						/>
					</Route>
				</Route>

				{/* Public Routes */}
				<Route element={<LoginGuard />}>

					<Route exact path="/login" element={
						<React.Suspense fallback={<div>...</div>}>
							<Login />
						</React.Suspense>
					}/>
				</Route>
			</Routes>
		</AuthProvider>
	)
}


export default App;

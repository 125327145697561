
import React, { useContext, useEffect, useMemo, useState } from 'react'
import {motion} from 'framer-motion'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Auth } from 'aws-amplify';
import { AccountContext } from '../../auth/AuthProvider';

const Topbar = ({sidebarVisible, setSidebarVisible, sidebarMobileVisible, setSidebarMobileVisible}) => {
    const { width, height } = useWindowDimensions();
    const {userData, userProfile, getUserProfile} = useContext(AccountContext)
    const [profilePictureSrc, setProfilePictureSrc] = useState(null)

    const memoized = useMemo(() => ({profilePictureSrc, userProfile}), [profilePictureSrc, userProfile])

    async function logout() {
        try {
            await Auth.signOut();
            await getUserProfile()
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }


    useEffect(() => {
        // if(userProfile) {
        //     setProfilePictureSrc(`https://d1yr1ar2im5b78.cloudfront.net/public/profile/${userProfile.attributes.sub}.jpeg`)
        // }
    }, [userProfile])

  return (
    <motion.div className="w-full h-[4.25rem] flex flex-row justify-between border-b border-soft-grey py-3.5 px-7">
        <button className="h-6 aspect-square my-auto cursor-pointer" 
          onClick={() => {
            width >= 1024 ?
              (sidebarVisible ? setSidebarVisible(false) : setSidebarVisible(true)) :
              (sidebarMobileVisible ? setSidebarMobileVisible(false) : setSidebarMobileVisible(true))
          }}
        >
            
            <img src="/icons/hamburger.svg" alt="" />
        </button>
        {   

            memoized.userProfile &&
                
                <div className="hidden md:flex flex-row ml-auto justify-end items-center gap-x-3 group relative w-4/12">
                    <p>{userData && userData.name ? userData.name : userProfile.attributes.email}</p>
                    {/* <p>{ userProfile.attributes.email}</p> */}
                        {/* <img 
                            src={memoized.profilePictureSrc || '/global/no-profile.jpg'}  
                            alt="Profile Picture" 
                            className="w-8 h-8 object-cover object-center rounded-full"
                            onError={(e) => {
                            e.target.src = '/global/no-profile.jpg';
                            }}
                        /> */}
                    <div className="absolute right-0 top-full min-w-3/4 h-fit pt-4 hidden group-hover:block z-50">
                        <div className="bg-white border border-medium-grey pt-6">
                            <div className="py-3 flex flex-col items-center gap-y-4 pb-6 px-4">
                                {/* <img 
                                    src={memoized.profilePictureSrc || '/global/no-profile.jpg'}  
                                    alt="Profile Picture" 
                                    className="w-16 h-16 object-cover object-center rounded-full"
                                    onError={(e) => {
                                    e.target.src = '/global/no-profile.jpg';
                                    }}
                                /> */}
                                <div>
                                    <p className="text-sm leading-snug font-semibold text-center">{userData && userData.name ? userData.name : userProfile.attributes.email}</p>
                                    <p className="text-2xs leading-[1.8] overflow-hidden text-center">{ userProfile.attributes.email }</p>
                                </div>
                            </div>
                            <button className="py-3 text-white font-bold w-full text-center bg-soft-red" onClick={logout}>
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
        }
    </motion.div>
  )
}

export default Topbar
import React, { useState } from 'react'
import SidebarHeader from './SidebarHeader'
import SidebarMenu from './SidebarMenu'
import {AnimatePresence, motion} from 'framer-motion'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const Sidebar = ({sidebarVisible, setSidebarVisible, sidebarMobileVisible, setSidebarMobileVisible}) => {
  
  const { width, height } = useWindowDimensions();

  return (
    <>
      {
        sidebarMobileVisible && (
        <AnimatePresence>
          <motion.div className="lg:hidden z-40 top-0 left-0 w-full h-full fixed bg-modal-backdrop"
            animate={{
              opacity: width < 1024 && (!sidebarMobileVisible ? 0 : 1)
            }}
            onClick={() => setSidebarMobileVisible(false)}
          >

          </motion.div>
        </AnimatePresence>
        )
      }
      <motion.div className="w-72 min-w-[18rem] fixed top-0 left-0 lg:relative h-screen flex flex-col z-50"
        animate={{
          left: `${ width >= 1024 ? (!sidebarVisible ? '-18rem' : '0px') : (!sidebarMobileVisible ? '-18rem' : '0px')}`,
        }}
        transition="easeOut"
      >
        <div className="h-[4.25rem] w-72 min-w-[18rem] lg:w-full bg-white border-b border-r border-soft-grey flex justify-center items-center z-">
          <img src="/images/logo.svg" alt="Happy Journey With Joyce" />
        </div>
        <div className="flex-1 w-72 min-w-[18rem] lg:w-full overflow-y-auto sidebar">
          <nav className="sidebar-content overflow-x-hidden py-6 px-7 border-r border-soft-grey flex flex-col gap-y-5 min-h-full bg-white">
              <SidebarHeader title="Menu">
                <SidebarMenu image="/icons/sidebar/blog.svg" link="/blogs" onClick={() => setSidebarMobileVisible(false)}>Blog</SidebarMenu>
                <SidebarMenu image="/icons/sidebar/schedule.svg" link="/schedule" onClick={() => setSidebarMobileVisible(false)}>Schedule</SidebarMenu>
              </SidebarHeader>

              
          </nav>
        </div>
      </motion.div>
    </>
  )
}

export default Sidebar